import React from 'react';

const RcGenericTitle = ( { children,
                           innerClassName = '',
                           noMargin = false,
                           className = '' }) => {

  const marginCSS = noMargin ? "" :  " mb-4 "

  if (typeof children === 'string') {
    children = <h1>{children}</h1>;
  }

  return <div className={ className + " " + marginCSS + " row contentCaption "}>
      <div className={"col-12 py-5 " + innerClassName}>
        {children}
      </div>
    </div>;
};

export default RcGenericTitle;
