import React from 'react';
import * as styles from './chevron-link.module.scss';
import ChevronRight from '../../icons/fontawesome/light/chevron-right.svg';
import { Link } from "gatsby"

const ChevronLink = ( { to, text, className = '' } ) => {

  return <Link to={to} className={"c-pointer d-flex align-items-center arrowLink " + className + " " + styles.chevronLink} >
    <ChevronRight className={"svgFill"} />
    {text}
  </Link>

};

export default ChevronLink;
