import React from 'react';
import * as styles from './rcDefaultPageTeaser.module.scss';

const RcDefaultPageTeaser = ({ children, className = '', style = 'default'}) => {

  let innerClassName = ' py-5';

  if (style === 'small') {
    innerClassName = " py-3 py-xl-4";
  }

  return <div className={styles.teaser + " shadow-xl " + className + ' ' }>
    <div className="container">
      <div className="row">
        <div className={"col-12 " + innerClassName}>
          { children}
        </div>
      </div>
    </div>
  </div>;

}

export default RcDefaultPageTeaser;
