import React from 'react';
import * as styles from './rcCard.module.scss';
import Badge from "../../helper-components/badge/badge"
import { GatsbyImage } from "gatsby-plugin-image";
import ChevronLink from "../../helper-components/chevron-link/chevron-link"
import Link from "gatsby-link"

import StarSVG from "../../icons/fontawesome/solid/star.svg";

const RcCard = ({ to = "/", img, caption, children,
                style = 'default',
                description = '',
                isPremium = false,
                innerClassName = '', className = '',
                badge = null, badgeVariant = 'primary',
                containImage = false }) => {

  if (typeof children === 'string') {
    children = <p>{children}</p>;
  }

  return (
    <div className={"col-xl-3 col-12 col-sm-6 col-lg-4 " + className + " " + styles[style] + " " + styles.cardWrapper}>

      <div className={styles.card + " " + innerClassName + " h-100 shadow bg-white"}>

        <div className={styles.cardImage}>
          <Link to={to} className={"h-100 w-100 p-relative"}>
            {typeof img === 'string' &&
            <img className={styles.cardImage} src={img}/>
            }

            {typeof  img !== 'string' &&
            <GatsbyImage image={img} className={styles.cardImageImg + " img-fluid " + (containImage ? styles.cardImageImgContain : "")} />
            }
          </Link>
        </div>

        <div className={styles.cardContent + " px-4 pt-3"}>
          { badge && <Badge variant={badgeVariant} className="mb-3">{badge}</Badge> }
          <div className={styles.caption + " d-flex align-items-center"}>
            { isPremium && <StarSVG className={"mr-1 svgFill " + styles.premiumSVG} /> }
            {caption}
          </div>
          { description && <div className={styles.description}>{description}</div> }
          { children }
        </div>

        <div className={styles.cardActionLink + " px-4 "}>
          <ChevronLink className={"mt-3 mb-4 align-self-end"} to={to} text={"Details"} />
        </div>

      </div>

    </div>
  );

};

export default RcCard;
