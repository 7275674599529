import React from 'react';
import './badge.scss';

const Badge = ({ children = '', className = '', variant = 'primary'}) => {
  return <div className={className + ' rc-badge ' + variant}>
    {children}
  </div>
};

export default Badge;
