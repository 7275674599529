import React from 'react';
import { graphql } from "gatsby"
import Layout from "../page-components/layout"
import SEO from "../page-components/seo"
import RcGenericTitle from "../page-components/content/rcGenericTitle"
import RcCard from "../components/card/rcCard"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"

const BeratungPage = ( { data } ) => {

  return <Layout>

    <SEO title={"Beratung"} />

    <RcDefaultPageTeaser>
      <h1>Beratung</h1>
    </RcDefaultPageTeaser>

    <div className="container pt-4 bg-white h-100">

      <div className="row pb-5">
        { data.allWpPage.nodes.map(
          n =>  <RcCard to={"/" + n.slug}
                        caption={n.title}
                        className={"mb-4"}
                        innerClassName={"h-100"}
                        description={n.acf.description}
                        img={n.acf?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData} />
        )}
      </div>

    </div>

  </Layout>

};

export default BeratungPage;

export const query = graphql`
  query BeratungPageQuery {
   
    allWpPage(filter: {acf: {art: {in: [ "beratung" ] }}}) {
      nodes {
        acf {
          art
          description
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        slug
        title
      }
    }
    
    
  }
    
    
`
